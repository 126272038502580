import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Logo from 'src/components/logo';
import HomeRegister from 'src/sections/home/HomeRegister';
import { LanguagePopover } from '../_common';

// ----------------------------------------------------------------------

export default function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        position: 'relative',
        bgcolor: 'background.default',
        pb: 2,
      }}
    >
      <HomeRegister />

      <Container>
        <Box
          sx={{
            width: '100%',
            height: '2px',
            background: (theme) => theme.palette.primary.main,
            mt: 6,
            mb: 2,
          }}
        />

        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Logo />

          <LanguagePopover />
        </Stack>
      </Container>
    </Box>
  );
}
