import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { RouterLink } from 'src/routes/components';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ type = 'full', disabledLink = false, sx, ...other }, ref) => {
  const theme = useTheme();

  const logo = (
    <Box
      component="img"
      srcSet={type === 'full' ? '/logo/logo_full.png 2x' : '/logo/logo_single.png 2x'}
      sx={{ height: 28.59, cursor: 'pointer', ...sx }}
    />
  );

  if (disabledLink) {
    return logo;
  }

  return (
    <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  type: PropTypes.string,
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default Logo;
