import { enUS as enUSCore, koKR as koKRCore } from '@mui/material/locale';
import { enUS as enUSDataGrid, koKR as koKRDataGrid } from '@mui/x-data-grid';
import { enUS as enUSDate, koKR as koKRDate } from '@mui/x-date-pickers/locales';
import { enUS as enUSAdapter, ko as koKRAdapter } from 'date-fns/locale';
import merge from 'lodash/merge';

// ----------------------------------------------------------------------

export const allLangs = [
  {
    label: 'English',
    value: 'en',
    systemValue: merge(enUSDate, enUSDataGrid, enUSCore),
    adapterLocale: enUSAdapter,
    icon: 'flagpack:gb-nir',
  },
  {
    label: 'Korean',
    value: 'ko',
    systemValue: merge(koKRDate, koKRDataGrid, koKRCore),
    adapterLocale: koKRAdapter,
    icon: 'flagpack:kr',
  },
];

export const defaultLang = allLangs[0]; // English
