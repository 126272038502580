import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

export const navConfig = [
  {
    title: 'l_001',
    icon: <Iconify icon="solar:home-2-bold-duotone" />,
    path: '/',
  },
  {
    title: 'l_002',
    icon: <Iconify icon="solar:atom-bold-duotone" />,
    path: '/about-us',
  },
  {
    title: 'l_003',
    icon: <Iconify icon="solar:atom-bold-duotone" />,
    path: '/token',
  },
  {
    title: 'l_004',
    icon: <Iconify icon="solar:atom-bold-duotone" />,
    path: '#',
    paths: {
      en: 'https://whitepaper-en.pomerz.com/',
      ko: 'https://whitepaper-kr.pomerz.com/',
    },
  },
];
