import { Box, Container, Stack, useTheme } from '@mui/material';
import { m } from 'framer-motion';
import { MotionViewport, varFade } from 'src/components/animate';
import { useLocales } from 'src/locales';

function HomeRegister() {
  const theme = useTheme();
  const { t } = useLocales();

  return (
    <Container component={MotionViewport}>
      <Stack pt={{ xs: 24, md: 32 }}>
        <Stack
          component={m.div}
          variants={varFade().in}
          direction={{ xs: 'column', lg: 'row' }}
          alignItems={{ xs: 'center', lg: 'flex-end' }}
          p={6}
          borderRadius={`${theme.shape.borderRadius}px`}
          sx={{ background: theme.palette.secondary.main }}
        >
          <Box
            component={m.img}
            srcSet="/assets/images/img_home_hero.png 2x"
            sx={{
              marginTop: '-250px',
              transform: 'rotateY(-180deg)',
              height: 450,
              objectFit: 'contain',
            }}
          />

          <Stack
            direction="row"
            flex={1}
            borderRadius={`${theme.shape.borderRadius / 2}px`}
            overflow="hidden"
          >
            <Box
              component="input"
              placeholder={t('l_090')}
              sx={{
                flex: 1,
                outline: 'none',
                border: 'none',
                background: '#FFF',
                p: 2,
              }}
            />
            <Box
              component="button"
              sx={{
                border: 'none',
                background: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
                px: 2,
              }}
            >
              {t('l_091')}
            </Box>
          </Stack>
        </Stack>
      </Stack>
    </Container>
  );
}

export default HomeRegister;
