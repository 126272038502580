import { Navigate, useRoutes } from 'react-router-dom';
import MainLayout from 'src/layouts/main';
import { HomePage, mainRoutes } from './main';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: (
        <MainLayout>
          <HomePage />
        </MainLayout>
      ),
    },

    ...mainRoutes,
    { path: '*', element: <Navigate to="/" replace /> },
  ]);
}
