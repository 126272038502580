import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import { alpha, useTheme } from '@mui/material/styles';
import Logo from 'src/components/logo';
import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';
import { useLocales } from 'src/locales';
import { bgBlur } from 'src/theme/css';
import { HeaderShadow, LanguagePopover } from '../_common';
import { HEADER } from '../config-layout';
import { navConfig } from './config-navigation';
import NavDesktop from './nav/desktop';
import NavMobile from './nav/mobile';

// ----------------------------------------------------------------------

export default function Header() {
  const theme = useTheme();
  const { t } = useLocales();

  const mdUp = useResponsive('up', 'md');

  const offsetTop = useOffSetTop(HEADER.H_DESKTOP);

  return (
    <AppBar>
      <Toolbar
        disableGutters
        sx={{
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_DESKTOP,
          },
          transition: theme.transitions.create(['height'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          ...(offsetTop && {
            ...bgBlur({
              color: theme.palette.background.default,
            }),
            height: {
              md: HEADER.H_DESKTOP_OFFSET,
            },
          }),
        }}
      >
        <Container
          sx={{ height: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
        >
          <Logo />

          {mdUp && <NavDesktop offsetTop={offsetTop} data={navConfig} />}

          <Stack spacing={2} alignItems="center" direction={{ xs: 'row', md: 'row-reverse' }}>
            {mdUp && (
              <Button
                size="large"
                variant="outlined"
                target="_blank"
                rel="noopener"
                href="https://t.me/Pomerz_bot"
                sx={{
                  border: `2px solid ${theme.palette.secondary.main}`,
                  fontWeight: 500,
                  fontSize: 18,
                  color: theme.palette.secondary.main,
                  '&:hover': {
                    background: alpha(theme.palette.secondary.main, 0.1),
                  },
                }}
              >
                {t('l_092')}
              </Button>
            )}

            {!mdUp && <NavMobile offsetTop={offsetTop} data={navConfig} />}
          </Stack>
        </Container>
      </Toolbar>

      {offsetTop && <HeaderShadow />}
    </AppBar>
  );
}
