import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { SplashScreen } from 'src/components/loading-screen';
import MainLayout from 'src/layouts/main';

// ----------------------------------------------------------------------

export const HomePage = lazy(() => import('src/pages/home'));
const AboutPage = lazy(() => import('src/pages/about-us'));
const AboutTokenPage = lazy(() => import('src/pages/AboutToken'));

// ----------------------------------------------------------------------

export const mainRoutes = [
  {
    element: (
      <MainLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </MainLayout>
    ),
    children: [
      { path: 'about-us', element: <AboutPage /> },
      { path: 'token', element: <AboutTokenPage /> },
    ],
  },
];
